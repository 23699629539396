import * as React from 'react';
import Link from 'next/link';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';

import { Text, themeProps, UnstyledButton, Box, TextProps } from 'components/design-system';
import { logEventClick } from 'utils/analytics';
import NavLinkRoot from './NavLinkRoot';

interface NavLinkProps {
  title: string;
  href: string;
  as?: string;
  isActive?: boolean;
  icon?: React.ReactNode;
  isDark?: boolean;
  scale?: TextProps['variant'];
}

const secondaryNavHeight = 36;

const SecondaryNavigationBase = styled(NavLinkRoot)<Pick<NavLinkProps, 'isActive'>>`
  display: flex;
  align-items: center;
  margin-right: ${themeProps.space.md}px;
  height: ${secondaryNavHeight}px;
  text-decoration: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${props =>
    props.isActive ? themeGet('colors.brandred', themeProps.colors.brandred) : 'transparent'};
  white-space: nowrap;

  &:hover,
  &:focus {
    border-bottom-color: ${themeGet('colors.brandred', themeProps.colors.brandred)};
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${themeProps.mediaQueries.md} {
    margin-right: ${themeProps.space.lg}px;
  }
`;

const LinkIcon = styled(UnstyledButton)`
  display: none;
  &:focus {
    outline: none;
  }
`;

const SecondaryNavWrapper = styled(Box)`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
`;

interface FadeBoxProps {
  direction: string;
  color: string;
}

const FadeBox = styled(Box)<FadeBoxProps>`
  position: absolute;
  z-index: 1;
  height: ${secondaryNavHeight}px;
  width: ${themeProps.space.md}px;
  background: linear-gradient(
    ${props => props.direction},
    transparent 0%,
    ${props => themeGet(`colors.${props.color}`, 'transparent')} 100%
  );

  ${themeProps.mediaQueries.md} {
    width: ${themeProps.space.lg}px;
  }
`;

const SecondaryNavLinkText = styled(Text)`
  font-size: 13px;
  line-height: 16px;

  ${themeProps.mediaQueries.md} {
    font-size: 15px;
    line-height: 18px;
  }
`;

const SecondaryNavLink: React.FC<NavLinkProps> = ({
  title,
  href,
  as,
  isActive,
  icon,
  isDark,
  scale,
}) => {
  return (
    <Link href={href} as={as} passHref>
      <SecondaryNavigationBase isActive={isActive} onClick={() => logEventClick(title)}>
        {icon && (
          <LinkIcon type="button" backgroundColor={isDark ? '#3c4249' : 'accents01'}>
            {icon}
          </LinkIcon>
        )}
        <Box>
          <SecondaryNavLinkText variant={scale || 300}>{title}</SecondaryNavLinkText>
        </Box>
      </SecondaryNavigationBase>
    </Link>
  );
};

SecondaryNavLink.defaultProps = {
  isActive: false,
};

export { SecondaryNavWrapper, SecondaryNavLink, FadeBox };
