import * as React from 'react';

const OptionIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 17,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 11 1" fill="none" {...rest}>
      <path
        d="M6 1C6 0.46875 5.53125 0 5 0C4.4375 0 4 0.46875 4 1C4 1.5625 4.4375 2 5 2C5.53125 2 6 1.5625 6 1ZM8.75 0C8.1875 0 7.75 0.46875 7.75 1C7.75 1.5625 8.1875 2 8.75 2C9.28125 2 9.75 1.5625 9.75 1C9.75 0.46875 9.28125 0 8.75 0ZM1.25 0C0.6875 0 0.25 0.46875 0.25 1C0.25 1.5625 0.6875 2 1.25 2C1.78125 2 2.25 1.5625 2.25 1C2.25 0.46875 1.78125 0 1.25 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default OptionIcon;
