import * as React from 'react';

const ProfileIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 17,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 16" fill="none" {...rest}>
      <path
        d="M10 0H2C0.875 0 0 0.90625 0 2V14C0 15.125 0.875 16 2 16H10C11.0938 16 12 15.125 12 14V2C12 0.90625 11.0938 0 10 0ZM11 14C11 14.5625 10.5312 15 10 15H2C1.4375 15 1 14.5625 1 14V2C1 1.46875 1.4375 1 2 1H10C10.5312 1 11 1.46875 11 2V14ZM6 9C7.375 9 8.5 7.90625 8.5 6.5C8.5 5.125 7.375 4 6 4C4.59375 4 3.5 5.125 3.5 6.5C3.5 7.90625 4.59375 9 6 9ZM6 5C6.8125 5 7.5 5.6875 7.5 6.5C7.5 7.34375 6.8125 8 6 8C5.15625 8 4.5 7.34375 4.5 6.5C4.5 5.6875 5.15625 5 6 5ZM7.4375 9.5C6.84375 9.5 6.6875 9.71875 6 9.71875C5.28125 9.71875 5.125 9.5 4.53125 9.5C3.875 9.5 3.21875 9.8125 2.84375 10.375C2.625 10.7188 2.5 11.0938 2.5 11.5312V12.75C2.5 12.9062 2.59375 13 2.75 13H3.25C3.375 13 3.5 12.9062 3.5 12.75V11.5312C3.5 11.0938 3.78125 10.5 4.53125 10.5C4.90625 10.5 5.1875 10.7188 6 10.7188C6.78125 10.7188 7.0625 10.5 7.4375 10.5C8.21875 10.5 8.5 11.0938 8.5 11.5312V12.75C8.5 12.9062 8.59375 13 8.75 13H9.25C9.375 13 9.5 12.9062 9.5 12.75V11.5312C9.5 11.0938 9.34375 10.7188 9.125 10.375C8.75 9.8125 8.09375 9.5 7.4375 9.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProfileIcon;
