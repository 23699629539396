import React from 'react';

const FacebookIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  fill,
  width,
  height,
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 17" fill="none" {...rest}>
      <path
        d="M8.719 9.5l.437-2.875H6.375V4.75c0-.813.375-1.563 1.625-1.563h1.281V.72S8.125.5 7.031.5C4.75.5 3.25 1.906 3.25 4.406v2.219H.687V9.5H3.25v7h3.125v-7h2.344z"
        fill={fill}
      />
    </svg>
  );
};

export default FacebookIcon;
