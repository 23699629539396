import * as React from 'react';
import { themeProps, Theme, GlobalStyles } from 'components/design-system';
import useDarkMode from 'utils/useDarkMode';

const lightThemeProps = {
  ...themeProps,
  colors: {
    ...themeProps.colors,
    background: '#f1f2f3',
    accents01: '#e1e2e6',
    accents02: '#b8bcc6',
    accents03: '#858a93',
    accents04: '#85888d',
    accents05: '#666b73',
    accents06: '#3c4249',
    accents07: '#2e343b',
    accents08: '#282d33',
    foreground: '#22272c',

    hoverTable: '#e1e2e6',
    progressBar: '#e1e2e6',
    card: '#ffffff', // white
    buttonlightmode: '#d8232a', // brandred
    buttonlightmodetext: '#ffffff', // white
    buttondarkmode: '#22272c', // foreground
    buttondarkmodetext: '#f1f2f3', // background
    navgridbgmobile: '#ffffff', // white
  },
};

const ThemeWrapper: React.FC = ({ children }) => {
  const [isDarkMode] = useDarkMode();

  const currentTheme = React.useMemo(() => {
    if (isDarkMode) {
      return themeProps;
    }

    return lightThemeProps;
  }, [isDarkMode]);

  return (
    <Theme customTheme={currentTheme}>
      <GlobalStyles />
      {children}
    </Theme>
  );
};

export default ThemeWrapper;
