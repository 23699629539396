import * as React from 'react';
import { Box } from 'components/design-system';

const LayoutRoot: React.SFC = ({ children }) => (
  <Box
    as="main"
    display="flex"
    flexDirection="column"
    position="relative"
    minHeight="100vh"
    overflowX="hidden"
    color="foreground"
    backgroundColor="background"
  >
    {children}
  </Box>
);

export default LayoutRoot;
