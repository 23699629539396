import * as React from 'react';
import styled from '@emotion/styled';
import {
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  grid,
  GridProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  background,
  BackgroundProps,
  color,
  ColorProps,
  border,
  BorderProps,
  shadow,
  ShadowProps,
} from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop';

export interface TableProps
  extends LayoutProps,
    FlexboxProps,
    PositionProps,
    GridProps,
    SpaceProps,
    TypographyProps,
    BackgroundProps,
    ColorProps,
    BorderProps,
    ShadowProps {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<unknown>;
  className?: string;
  style?: React.CSSProperties;
  /** Override default color prop. */
  color?: string;
}

const Table = styled('table', { shouldForwardProp })<TableProps>`
  ${layout}
  ${flexbox}
  ${position}
  ${grid}
  ${space}
  ${typography}
  ${background}
  ${color}
  ${border}
  ${shadow}
  width:100%;
  white-space: nowrap;
  table-layout: auto;
  /* td,
  th {
    border: none;
  } */
  td,
  /* th {
    border: 1px solid;
  } */

  td {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    border-bottom: 1px solid;
  }

  thead > tr > th {
    text-align: start;
    padding-bottom: 15px;
    border-bottom: 2px solid;
  }
`;

Table.displayName = 'Table';

export default Table;
