import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import * as React from 'react';
import { themeProps, Space } from '../../Theme';
import { Stack } from '../Stack';

export interface BlockquoteProps {
  className?: string;
  style?: React.CSSProperties;
  spacing?: Space;
}

const Root = styled(Stack)`
  border-left: 1px solid ${themeGet('colors.foreground', themeProps.colors.foreground)};
`;

const Blockquote: React.FC<BlockquoteProps> = ({ children, spacing = 'xs', ...rest }) => (
  <Root as="blockquote" pl="sm" pr="md" mx={0} my="md" spacing={spacing} {...rest}>
    {children}
  </Root>
);

Blockquote.displayName = 'ListItem';

export default Blockquote;
