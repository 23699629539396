import * as React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';

import { Text, Box, themeProps, UnstyledAnchor } from 'components/design-system';
import { logEventClick } from 'utils/analytics';
import useDarkMode from 'utils/useDarkMode';

import Content from './Content';
import Column from './Column';

import FacebookIcon from '../../assets/Icons/FacebookIcon';
import InstagramIcon from '../../assets/Icons/InstagramIcon';
import TwitterIcon from '../../assets/Icons/TwitterIcon';

const FooterElement = Content.withComponent('footer');

const Root = styled(FooterElement)`
  padding: 0px ${themeProps.space.md}px 84px;

  ${themeProps.mediaQueries.md} {
    padding: 48px ${themeProps.space.lg}px 84px;
  }
`;

const TextLink = styled(Text)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const FooterGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'socials'
    'copyright'
    'privacy';
  grid-gap: 16px;

  ${themeProps.mediaQueries.md} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'copyright socials privacy';
  }
`;

const FooterCopyright = styled(Box)`
  grid-area: copyright;
  justify-content: center;
  text-align: center;
  margin: ${themeProps.space.xs}px 0;

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  ${themeProps.mediaQueries.md} {
    justify-content: flex-start;
    text-align: left;
  }
`;

const FooterSocials = styled(Box)`
  grid-area: socials;
  align-items: center;
  margin: ${themeProps.space.xs}px 0;
`;

const SocialLink = styled(UnstyledAnchor)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const FooterPrivacy = styled(Box)`
  grid-area: privacy;
  justify-content: center;
  align-items: center;

  ${themeProps.mediaQueries.md} {
    align-items: flex-end;
  }
`;

const Footer: React.FC = () => {
  const [isDarkMode] = useDarkMode();

  return (
    <Root noPadding noFlex>
      <Column>
        <Box
          display="flex"
          flexDirection="column"
          paddingTop="md"
          borderTopWidth="1px"
          borderTopStyle="solid"
          borderTopColor="accents02"
        >
          <FooterGrid>
            <FooterCopyright display="flex" alignItems="center">
              <Text
                variant={300}
                as="span"
                color={isDarkMode ? themeProps.colors.accents07 : themeProps.colors.accents04}
              >
                KawalCOVID19.id disebarluaskan di bawah Lisensi &zwnj;
                <a
                  href="https://creativecommons.org/licenses/by-nc-nd/4.0/legalcode.id"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Creative Commons Atribusi-NonKomersial-TanpaTurunan 4.0 Internasional
                </a>
              </Text>
            </FooterCopyright>
            <FooterSocials display="flex" justifyContent="center">
              <Box display="grid" gridTemplateColumns="24px 24px 24px" gridGap="md">
                <SocialLink
                  href="https://instagram.com/kawalcovid19.id"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => logEventClick('Instagram')}
                >
                  <InstagramIcon
                    fill={isDarkMode ? themeProps.colors.accents07 : themeProps.colors.accents02}
                    height={24}
                  />
                </SocialLink>
                <SocialLink
                  href="https://twitter.com/KawalCOVID19"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => logEventClick('Twitter')}
                >
                  <TwitterIcon
                    fill={isDarkMode ? themeProps.colors.accents07 : themeProps.colors.accents02}
                    height={20}
                  />
                </SocialLink>
                <SocialLink
                  href="https://www.facebook.com/KawalCOVID19"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => logEventClick('Facebook')}
                >
                  <FacebookIcon
                    fill={isDarkMode ? themeProps.colors.accents07 : themeProps.colors.accents02}
                    height={24}
                  />
                </SocialLink>
              </Box>
            </FooterSocials>
            <FooterPrivacy display="flex" flexDirection="column">
              <Link href="/kebijakan-privasi" passHref>
                <TextLink
                  variant={300}
                  as="a"
                  display="inline-block"
                  fontWeight={700}
                  color={isDarkMode ? themeProps.colors.accents07 : themeProps.colors.accents04}
                >
                  Kebijakan Privasi
                </TextLink>
              </Link>
            </FooterPrivacy>
          </FooterGrid>
        </Box>
      </Column>
    </Root>
  );
};

export default Footer;
