import * as React from 'react';

const LockIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 17,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 16" fill="none" {...rest}>
      <path
        d="M12.5 7H12V5.0625C12 2.3125 9.75 0.03125 7 0C4.25 0 2 2.25 2 5V7H1.5C0.65625 7 0 7.6875 0 8.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V8.5C14 7.6875 13.3125 7 12.5 7ZM3 5C3 2.8125 4.78125 1 7 1C9.1875 1 11 2.8125 11 5V7H3V5ZM12.5 15H1.5C1.21875 15 1 14.7812 1 14.5V8.5C1 8.25 1.21875 8 1.5 8H12.5C12.75 8 13 8.25 13 8.5V14.5C13 14.7812 12.75 15 12.5 15Z"
        fill={fill}
      />
    </svg>
  );
};

export default LockIcon;
