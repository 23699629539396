import * as React from 'react';
import { ThemeProvider } from '@emotion/react';
import {
  colors,
  space,
  fonts,
  breakpoints,
  widths,
  mediaQueries,
  shadows,
  componentStyles,
} from './utils';

interface ThemeProviderProps {
  customTheme?: Record<string, unknown>;
}

export const themeProps = {
  colors,
  space,
  fonts,
  widths,
  mediaQueries,
  breakpoints,
  shadows,
  componentStyles,
};

export const Theme: React.FC<ThemeProviderProps> = ({ children, customTheme }) => {
  return <ThemeProvider theme={customTheme || themeProps}>{children}</ThemeProvider>;
};

export type Color = keyof typeof themeProps['colors'];
export type Space = keyof typeof themeProps['space'];
export type TextScale = keyof typeof themeProps['componentStyles']['typography']['textScale'];
export type ParagraphScale = keyof typeof themeProps['componentStyles']['typography']['paragraphScale'];
