import styled from '@emotion/styled';
import { Box, BoxProps, Heading, Text } from 'components/design-system';
import Link from 'next/link';
import * as React from 'react';
import { logEventClick } from 'utils/analytics';

interface HomepageWidgetProps extends Omit<BoxProps, 'as'> {
  title: string;
  readMoreText?: string;
  href?: string;
  as?: string;
}

const TextLink = Text.withComponent('a');

const ReadmoreLink = styled(TextLink)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const HomepageWidget: React.FC<HomepageWidgetProps> = ({
  title,
  readMoreText,
  href,
  as,
  children,
  ...rest
}) => {
  return (
    <Box
      display="grid"
      gridTemplateAreas={[
        null,
        null,
        null,
        `
          "secthead readmore"
          "content content"
        `,
      ]}
      {...rest}
    >
      <Box mb={['md', null, null, 'xl']} gridArea={[null, null, null, 'secthead']}>
        <Heading variant={800} as="h2">
          {title}
        </Heading>
      </Box>
      <Box gridArea={[null, null, null, 'content']}>{children}</Box>
      {readMoreText && href && (
        <Box
          gridArea={[null, null, null, 'readmore']}
          mt={['md', null, null, 0]}
          mb={[null, null, null, 'xl']}
          justifySelf={[null, null, null, 'end']}
        >
          <Link href={href} as={as} passHref>
            <ReadmoreLink
              variant={500}
              fontWeight={600}
              color="primary02"
              onClick={() => logEventClick(readMoreText)}
            >
              {readMoreText} &rarr;
            </ReadmoreLink>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default HomepageWidget;
