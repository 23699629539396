import * as React from 'react';
import { Global, css, useTheme, Theme } from '@emotion/react';
import { transparentize } from 'polished';

const progressBarColor = (theme: Theme) => theme.colors.foreground;

const globalCSS = (theme: Theme) => css`
  :root {
    --reach-skip-nav: 1;
  }

  html {
    height: 100%;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    position: relative;
    min-height: 100%;
    margin: 0;
    font-family: ${theme.fonts.sansSerif};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    background-color: transparent;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  svg {
    shape-rendering: crispEdges;
  }
  svg path,
  svg circle,
  svg polygon,
  svg rect,
  svg line {
    shape-rendering: geometricprecision;
  }

  ::selection {
    background-color: ${theme.colors.highlight04};
    color: ${theme.colors.foreground};
  }

  ::-moz-selection {
    background-color: ${theme.colors.highlight04};
    color: ${theme.colors.foreground};
  }

  #__next {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }

  a {
    color: inherit;
  }

  figure img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .noscroll {
    overflow: hidden;
  }

  [data-reach-skip-nav-link] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }

  [data-reach-skip-nav-link]:focus {
    padding: 1rem;
    position: fixed;
    top: 10px;
    left: 10px;
    background: ${theme.colors.accents01};
    color: ${theme.colors.foreground};
    z-index: 1;
    width: auto;
    height: auto;
    clip: auto;
  }

  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
    z-index: 1030;
  }

  #nprogress .bar {
    background: ${progressBarColor(theme)};

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${progressBarColor(theme)}, 0 0 5px ${progressBarColor(theme)};
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  #nprogress .spinner-icon {
    display: none;
  }

  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* WordPress block table */
  .wp-block-table {
    margin: 0;
  }

  .wp-block-table .has-subtle-light-gray-background-color {
    background-color: ${theme.colors.accents01};
  }
  .wp-block-table .has-subtle-pale-green-background-color {
    background-color: ${transparentize(0.9, theme.colors.success02)};
  }
  .wp-block-table .has-subtle-pale-blue-background-color {
    background-color: ${transparentize(0.9, theme.colors.primary02)};
  }
  .wp-block-table .has-subtle-pale-pink-background-color {
    background-color: ${transparentize(0.9, theme.colors.highlight02)};
  }
  .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: ${theme.colors.accents01};
  }

  .wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
    background-color: ${theme.colors.accents01};
  }
  .wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
    background-color: ${transparentize(0.9, theme.colors.success02)};
  }
  .wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
    background-color: ${transparentize(0.9, theme.colors.primary02)};
  }
  .wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
    background-color: ${transparentize(0.9, theme.colors.highlight02)};
  }
`;

const GlobalStyles: React.FC = () => {
  const theme: Theme = useTheme();
  return <Global styles={globalCSS(theme)} />;
};

export default GlobalStyles;
