import * as React from 'react';

const SearchIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 17,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" {...rest}>
      <path
        d="M15.875 15.063l-4.031-4.032a.356.356 0 00-.25-.094h-.344C12.344 9.75 13 8.22 13 6.5 13 2.937 10.062 0 6.5 0 2.906 0 0 2.938 0 6.5 0 10.094 2.906 13 6.5 13c1.688 0 3.25-.656 4.406-1.719v.313c0 .125.031.219.094.281l4.031 4.031a.36.36 0 00.531 0l.313-.312a.36.36 0 000-.531zM6.5 12A5.468 5.468 0 011 6.5C1 3.469 3.438 1 6.5 1 9.531 1 12 3.469 12 6.5 12 9.563 9.531 12 6.5 12z"
        fill={fill}
      />
    </svg>
  );
};

export default SearchIcon;
