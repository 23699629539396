import * as React from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { themeProps } from 'components/design-system';

const SearchInput = styled('input')`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: ${themeGet('colors.accents01', themeProps.colors.accents01)};
  color: ${themeGet('colors.foreground', themeProps.colors.foreground)};
  outline: none;
  height: 60px;

  &:focus {
    border-color: ${themeGet('colors.brandred', themeProps.colors.brandred)};
  }
`;

const SearchWrapper = styled('div')`
  width: 100%;
  .algolia-autocomplete {
    width: inherit;
    .ds-dropdown-menu {
      width: inherit;
      max-width: 100%;
      margin-top: 12px;
      margin-left: 12px;
      margin-right: 12px;

      [class^='ds-dataset-'] {
        max-height: 90vh;
        overflow: auto;
        background-color: ${themeGet('colors.accents01', themeProps.colors.accents01)};
        border-color: ${themeGet('colors.accents01', themeProps.colors.accents01)};
      }
      &:before {
        background-color: ${themeGet('colors.accents01', themeProps.colors.accents01)};
        border-color: ${themeGet('colors.accents01', themeProps.colors.accents01)};
      }
      .ds-suggestions a:hover {
        .algolia-docsearch-suggestion {
          &--title {
            text-decoration: underline;
          }
          &--content {
            background: transparent;
          }
        }
      }
    }

    .algolia-docsearch-suggestion {
      text-decoration: none;
      background-color: ${themeGet('colors.accents01', themeProps.colors.accents01)};
      &--category-header,
      &--subcategory-column-text,
      &--title {
        color: ${themeGet('colors.foreground', themeProps.colors.foreground)};
      }
      &--highlight {
        color: ${themeGet('colors.accents02', themeProps.colors.primary02)};
        background-color: ${themeGet('colors.highlight04', themeProps.colors.highlight04)};
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .algolia-autocomplete {
      .ds-dropdown-menu {
        width: calc(100vw - 86px);
        min-width: calc(100vw - 86px);
        [class^='ds-dataset-'] {
          width: inherit;
        }
      }
      .algolia-docsearch-suggestion {
        &--category-header,
        &--title,
        &--text {
          width: 80vw;
        }
      }
      .algolia-docsearch-footer {
        width: 134px !important;
      }
    }
  }
`;

declare global {
  interface Window {
    docsearch: (param: Record<string, string | boolean>) => never;
  }
}

const SearchWidget: React.FC = () => {
  const input = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const { docsearch } = window;
      docsearch({
        apiKey: '3970d3d13e719b0178e330ebef7f44dc',
        indexName: 'kawalcovid19',
        appId: 'H51L3YGB63',
        inputSelector: '#search',
        debug: false,
      });
    }

    if (input.current) {
      // Immediately focus to element when page loads
      input.current.focus();
    }
  }, []);

  return (
    <SearchWrapper>
      <SearchInput type="text" id="search" placeholder="Pencarian" ref={input} />
    </SearchWrapper>
  );
};

export default SearchWidget;
