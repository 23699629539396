import React from 'react';

const InstagramIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  fill,
  width,
  height,
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" {...rest}>
      <path
        d="M7.5 3.906c-2 0-3.594 1.625-3.594 3.594 0 2 1.594 3.594 3.594 3.594A3.594 3.594 0 0011.094 7.5c0-1.969-1.625-3.594-3.594-3.594zm0 5.938A2.34 2.34 0 015.156 7.5c0-1.281 1.032-2.313 2.344-2.313A2.307 2.307 0 019.813 7.5c0 1.313-1.032 2.344-2.313 2.344zm4.563-6.063a.84.84 0 00-.844-.844.84.84 0 00-.844.844.84.84 0 00.844.844.84.84 0 00.844-.844zm2.374.844c-.062-1.125-.312-2.125-1.124-2.938C12.5.875 11.5.625 10.374.563 9.219.5 5.75.5 4.594.563 3.469.625 2.5.875 1.656 1.688.844 2.5.594 3.5.531 4.626.47 5.781.47 9.25.531 10.406c.063 1.125.313 2.094 1.125 2.938.844.812 1.813 1.062 2.938 1.125 1.156.062 4.625.062 5.781 0 1.125-.063 2.125-.313 2.938-1.125.812-.844 1.062-1.813 1.124-2.938.063-1.156.063-4.625 0-5.781zm-1.5 7c-.218.625-.718 1.094-1.312 1.344-.938.375-3.125.281-4.125.281-1.031 0-3.219.094-4.125-.281a2.37 2.37 0 01-1.344-1.344c-.375-.906-.281-3.094-.281-4.125 0-1-.094-3.188.281-4.125a2.41 2.41 0 011.344-1.313c.906-.374 3.094-.28 4.125-.28 1 0 3.188-.095 4.125.28.594.22 1.063.72 1.313 1.313.374.938.28 3.125.28 4.125 0 1.031.095 3.219-.28 4.125z"
        fill={fill}
      />
    </svg>
  );
};

export default InstagramIcon;
