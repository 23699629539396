import React from 'react';

const TwitterIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill, width, height, ...rest }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 13" fill="none" {...rest}>
      <path
        d="M14.844 3.25a6.952 6.952 0 001.625-1.688 6.151 6.151 0 01-1.875.5A3.206 3.206 0 0016.03.25a6.93 6.93 0 01-2.062.813A3.282 3.282 0 0011.563.03 3.28 3.28 0 008.28 3.312c0 .25.031.5.094.75A9.52 9.52 0 011.594.626a3.192 3.192 0 00-.438 1.656c0 1.125.563 2.125 1.469 2.719-.531-.031-1.063-.156-1.5-.406v.031A3.285 3.285 0 003.75 7.844c-.25.062-.563.125-.844.125-.219 0-.406-.032-.625-.063a3.265 3.265 0 003.063 2.282 6.597 6.597 0 01-4.063 1.406C1 11.594.75 11.563.5 11.53 1.938 12.47 3.656 13 5.531 13c6.032 0 9.313-4.969 9.313-9.313V3.25z"
        fill={fill}
      />
    </svg>
  );
};

export default TwitterIcon;
