import * as React from 'react';

const ChainIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 17,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" {...rest}>
      <path
        d="M10.4062 12.3438C11.9688 10.7812 11.9688 8.1875 10.4062 6.59375C10 6.21875 9.5625 5.9375 9.09375 5.71875C8.90625 5.65625 8.65625 5.75 8.59375 5.96875C8.5625 6.09375 8.53125 6.3125 8.5 6.4375C8.5 6.59375 8.59375 6.75 8.75 6.8125C9.0625 6.9375 9.34375 7.15625 9.59375 7.40625C10.75 8.53125 10.75 10.4062 9.625 11.5312L9.59375 11.5625L7.125 14.0312C5.96875 15.1875 4.125 15.1875 2.96875 14.0312C1.8125 12.875 1.8125 11.0312 2.96875 9.875C2.96875 9.875 4.8125 8.03125 5.3125 7.53125C5.40625 7.4375 5.4375 7.3125 5.4375 7.21875C5.40625 7.0625 5.40625 6.90625 5.40625 6.75C5.375 6.4375 5 6.28125 4.75 6.5C4.71875 6.53125 4.6875 6.5625 4.65625 6.59375L2.1875 9.09375C0.59375 10.6562 0.59375 13.25 2.1875 14.8125C3.75 16.4062 6.34375 16.4062 7.90625 14.8125L10.4062 12.3438ZM7.5625 9.4375C7.96875 9.8125 8.40625 10.0938 8.875 10.3125C9.0625 10.375 9.3125 10.2812 9.375 10.0625C9.40625 9.9375 9.4375 9.71875 9.46875 9.59375C9.46875 9.4375 9.375 9.28125 9.21875 9.21875C8.90625 9.09375 8.625 8.875 8.375 8.625C7.21875 7.5 7.21875 5.625 8.34375 4.5L8.375 4.46875L10.8438 2C12 0.84375 13.8438 0.84375 15 2C16.1562 3.15625 16.1562 5 15 6.15625C15 6.15625 13.1562 8 12.6562 8.5C12.5625 8.59375 12.5312 8.71875 12.5312 8.8125C12.5625 8.9375 12.5625 9.15625 12.5625 9.28125C12.5938 9.59375 12.9688 9.75 13.2188 9.53125C13.25 9.5 13.2812 9.46875 13.3125 9.4375L15.7812 6.9375C17.375 5.375 17.375 2.78125 15.7812 1.21875C14.2188 -0.375 11.625 -0.375 10.0625 1.21875L7.5625 3.6875C6 5.25 6 7.84375 7.5625 9.4375Z"
        fill={fill}
      />
    </svg>
  );
};

export default ChainIcon;
