import * as React from 'react';
import styled from '@emotion/styled';
import { Portal, UnstyledButton, Box } from 'components/design-system';
import { CloseIcon } from '../../components/icons';
import SearchWidget from './SearchWidget';

export const SearchModalButton = styled(UnstyledButton)``;

interface SearchModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const CloseButton = styled(UnstyledButton)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 0;
  outline: none;
`;

const SearchModal: React.FC<SearchModalProps> = ({ isOpen, onClose }) => {
  const toggleModal = () => {
    if (onClose) {
      onClose();
    }
  };

  React.useEffect(() => {
    document.body.classList.toggle('noscroll', isOpen);
  }, [isOpen]);

  const renderInnerContent = () => {
    if (isOpen) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          backgroundColor="background"
          color="foreground"
          zIndex={50}
        >
          <Box display="flex" flexDirection="row">
            <Box display="flex" flexDirection="row" alignItems="center" flex="1 1 auto" height={60}>
              <SearchWidget />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" height={60}>
              <CloseButton type="button" backgroundColor="brandred" onClick={toggleModal}>
                <CloseIcon />
              </CloseButton>
            </Box>
          </Box>
        </Box>
      );
    }

    return null;
  };

  return <Portal>{renderInnerContent()}</Portal>;
};

SearchModal.defaultProps = {
  isOpen: false,
  onClose: undefined,
};

export default SearchModal;
