import * as React from 'react';

const MobileIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 17,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 16" fill="none" {...rest}>
      <path
        d="M6 13C6 12.4688 5.53125 12 5 12C4.4375 12 4 12.4688 4 13C4 13.5625 4.4375 14 5 14C5.53125 14 6 13.5625 6 13ZM10 1.5C10 0.6875 9.3125 0 8.5 0H1.5C0.65625 0 0 0.6875 0 1.5V14.5C0 15.3438 0.65625 16 1.5 16H8.5C9.3125 16 10 15.3438 10 14.5V1.5ZM9 1.5V14.5C9 14.7812 8.75 15 8.5 15H1.5C1.21875 15 1 14.7812 1 14.5V1.5C1 1.25 1.21875 1 1.5 1H8.5C8.75 1 9 1.25 9 1.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default MobileIcon;
