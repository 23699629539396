import * as React from 'react';

const BerbagiIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 17,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" {...rest}>
      <path
        d="m14.26967,11.54012a0.39931,0.39931 0 0 1 0.3017,0.11535a0.42593,0.42593 0 0 1 -0.10648,0.63001l-5.77662,3.30093l-7.32947,0l0,-0.82523l6.74383,0a1.35764,1.35764 0 0 0 0.66551,-0.17747l5.19985,-2.99923a0.59452,0.59452 0 0 1 0.3017,-0.07986m0,-1.33102a1.88117,1.88117 0 0 0 -0.96721,0.25733l-5.19985,3.03472l-8.07485,0l0,2.60879a0.88735,0.88735 0 0 0 0.88735,0.88735l7.87075,0a0.88735,0.88735 0 0 0 0.23071,-0.04437l0.15972,0l0,0l5.93634,-3.47839a1.77469,1.77469 0 0 0 0.47029,-2.66204a1.69483,1.69483 0 0 0 -1.33102,-0.60339l0.01775,0l0.00001,0l0.00001,0z"
        fill={fill}
      />
      <path
        d="m6.55864,12.13465a1.38426,1.38426 0 0 1 -1.26891,0.78974l-3.93094,0l0,-0.82523l5.19985,0m0.74537,-1.33102l-6.38889,0a0.88735,0.88735 0 0 0 -0.88735,0.88735l0,2.59105l5.26196,0a2.71528,2.71528 0 0 0 2.72415,-2.67979l0,-0.05324a0.70987,0.70987 0 0 0 -0.70987,-0.70987l0,-0.03549l-0.00001,0l0.00001,-0.00001z"
        fill={fill}
      />
      <path
        d="m10.85339,1.4865a1.68596,1.68596 0 0 1 1.18904,2.86613l-0.88735,0.88735l-3.14121,3.14121l-3.13233,-3.13233l-0.88735,-0.88735a1.68596,1.68596 0 0 1 2.37809,-2.37809l0.70987,0.70987a1.33102,1.33102 0 0 0 1.88117,0l0.70987,-0.70987a1.65933,1.65933 0 0 1 1.18904,-0.48804m0,-1.33102a2.99923,2.99923 0 0 0 -2.12963,0.88735l-0.70987,0.70987l-0.70987,-0.70987a3.0081,3.0081 0 0 0 -4.26813,4.24151l0.88735,0.88735l3.88657,3.88657a0.25733,0.25733 0 0 0 0.37269,0l3.88657,-3.88657l0.88735,-0.88735a3.0081,3.0081 0 0 0 -2.12963,-5.13773l0.02662,0.00887l-0.00001,0l-0.00001,0z"
        fill={fill}
      />
    </svg>
  );
};

export default BerbagiIcon;
