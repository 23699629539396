import * as React from 'react';

const InformationIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 16,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M8 1.25C11.6875 1.25 14.75 4.28125 14.75 8C14.75 11.75 11.7188 14.75 8 14.75C4.25 14.75 1.25 11.75 1.25 8C1.25 4.28125 4.25 1.25 8 1.25ZM8 0.25C3.71875 0.25 0.25 3.75 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.25 15.75 15.75 12.2812 15.75 8C15.75 3.75 12.25 0.25 8 0.25ZM6.875 11C6.65625 11 6.5 11.1875 6.5 11.375V11.625C6.5 11.8438 6.65625 12 6.875 12H9.125C9.3125 12 9.5 11.8438 9.5 11.625V11.375C9.5 11.1875 9.3125 11 9.125 11H8.75V6.625C8.75 6.4375 8.5625 6.25 8.375 6.25H6.875C6.65625 6.25 6.5 6.4375 6.5 6.625V6.875C6.5 7.09375 6.65625 7.25 6.875 7.25H7.25V11H6.875ZM8 3.5C7.4375 3.5 7 3.96875 7 4.5C7 5.0625 7.4375 5.5 8 5.5C8.53125 5.5 9 5.0625 9 4.5C9 3.96875 8.53125 3.5 8 3.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default InformationIcon;
