import * as React from 'react';
import formatNumber from 'utils/formatNumber';
import { Box } from '../Box';
import { Text } from '../Typography';

export interface DataCardProps {
  color: string;
  value?: number;
  label: string;
}

const EMPTY_DASH = '----';

const DataCard: React.FC<DataCardProps> = React.memo(({ color, value, label }) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    px="md"
    pt="sm"
    pb="md"
    borderRadius={6}
    backgroundColor="card"
  >
    <Box textAlign="center">
      <Text display="block" variant={1100} color={color} fontFamily="monospace">
        {value ? formatNumber(value) : EMPTY_DASH}
      </Text>
      <Text display="block" mb="xxs" variant={400} color="accents08">
        {label}
      </Text>
    </Box>
  </Box>
));

export default DataCard;
