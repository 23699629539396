import * as React from 'react';
import Link from 'next/link';
import { themeGet } from '@styled-system/theme-get';
import styled from '@emotion/styled';

import { themeProps, Box, Text } from 'components/design-system';
import { logEventClick } from 'utils/analytics';
import NavLinkRoot from './NavLinkRoot';

interface NavLinkProps {
  title: string;
  href: string;
  as?: string;
  isActive?: boolean;
  icon?: React.ReactNode;
}

const MobileNavLinkBase = styled(NavLinkRoot)<Pick<NavLinkProps, 'isActive'>>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  font-size: 10px;
  margin-bottom: 2px;
`;

const LinkIcon = styled.div<Pick<NavLinkProps, 'isActive'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 4px 0;
  margin-bottom: 2px;
  border-radius: 50%;
  background: ${props =>
    props.isActive
      ? `${themeGet('colors.brandred', themeProps.colors.brandred)(props)}`
      : 'transparent'};
`;

const MobileNav = styled(Box)`
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 -${themeProps.space.md}px;
  backdrop-filter: blur(16px);
  box-shadow: ${themeProps.shadows.single};
  display: flex;
  justify-content: space-evenly;
  height: 54px;
  z-index: 1;

  ${themeProps.mediaQueries.sm} {
    display: none;
  }
`;

const MobileNavLink: React.FC<NavLinkProps> = ({ title, href, as, isActive, icon }) => {
  return (
    <Link href={href} as={as}>
      <MobileNavLinkBase isActive={isActive} onClick={() => logEventClick(title)}>
        <LinkIcon isActive={isActive}>{icon}</LinkIcon>
        <Text variant={100} style={{ fontWeight: isActive ? 'bold' : 'normal' }}>
          {title}
        </Text>
      </MobileNavLinkBase>
    </Link>
  );
};

MobileNavLink.defaultProps = {
  isActive: false,
};

export { MobileNav, MobileNavLink };
