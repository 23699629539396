/* eslint-disable react/no-array-index-key */

import * as React from 'react';
import Head from 'next/head';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import { initGA, logPageView } from 'utils/analytics';
// import QiscusWidget from 'modules/core/QiscusWidget';

import LayoutRoot from './LayoutRoot';
import { Navigation } from './Navigation';
import Footer from './Footer';

interface PageWrapperProps {
  title?: string;
  pageTitle?: string;
  author?: string;
  description?: string;
}

declare global {
  interface Window {
    GA_INITIALIZED?: boolean;
  }
}

const defaultTitle = 'Informasi Terkini COVID-19 di Indonesia | KawalCOVID19';
const defaultAuthor = 'KawalCOVID19';
const defaultDescription =
  'Kawal informasi seputar COVID-19 secara tepat dan akurat. Situs ini merupakan sumber informasi inisiatif sukarela netizen Indonesia pro-data; terdiri dari praktisi kesehatan, akademisi & profesional.';
const defaultKeywords =
  'coronavirus, corona, covid-19, covid 19, kawal corona, kawal covid-19, kawal covid 19, kawalcovid19, virus corona';

const PageWrapper: React.FC<PageWrapperProps> = ({
  author = defaultAuthor,
  children,
  title = defaultTitle,
  description = defaultDescription,
  pageTitle,
}) => {
  const noHTMLTagDescription = description.replace(/<\/?[^>]+(>|$)/g, ``);
  const metaAttributes = [
    {
      name: `description`,
      content: noHTMLTagDescription,
    },
    {
      name: 'keywords',
      content: defaultKeywords,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: noHTMLTagDescription,
    },
    {
      property: `og:image`,
      content: `https://kawalcovid19.id/android-chrome-192x192.png`,
    },
    {
      property: `og:image:width`,
      content: `192`,
    },
    {
      property: `og:image:height`,
      content: `192`,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: noHTMLTagDescription,
    },
  ];

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  }, []);

  return (
    <LayoutRoot>
      <Head>
        <title>{title}</title>
        {metaAttributes.map(attributes => (
          <meta key={attributes.name || attributes.property} {...attributes} />
        ))}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/docsearch.js@2.6.3/dist/cdn/docsearch.min.css"
        />
        <script src="https://cdn.jsdelivr.net/npm/docsearch.js@2.6.3/dist/cdn/docsearch.min.js" />
        <script
          type="text/javascript"
          src="https://cdn.jsdelivr.net/npm/powerbi-client@2.11.0/dist/powerbi.min.js"
        />
        {/* <QiscusWidget /> */}
      </Head>
      <SkipNavLink>Lanjut ke konten</SkipNavLink>
      <Navigation pageTitle={pageTitle} />
      <SkipNavContent />
      {children}
      <Footer />
    </LayoutRoot>
  );
};

export default PageWrapper;
