import * as React from 'react';

const OpenLinkIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 16,
  fill = '#3389fe',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" {...rest}>
      <path
        d="M13.75 8H13.25C13.0938 8 13 8.125 13 8.25V14.5C13 14.7812 12.75 15 12.5 15H1.5C1.21875 15 1 14.7812 1 14.5V3.5C1 3.25 1.21875 3 1.5 3H7.75C7.875 3 8 2.90625 8 2.75V2.25C8 2.125 7.875 2 7.75 2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V8.25C14 8.125 13.875 8 13.75 8ZM15.625 0L11.375 0.03125C11.1562 0.03125 11 0.1875 11 0.40625V0.71875C11 0.90625 11.1562 1.09375 11.375 1.09375L14.1875 1.0625V1.09375L4.09375 11.1875C4.03125 11.25 3.96875 11.375 3.96875 11.4688C3.96875 11.5625 4.03125 11.6562 4.09375 11.7188L4.28125 11.9062C4.34375 11.9688 4.4375 12.0312 4.53125 12.0312C4.625 12.0312 4.75 11.9688 4.8125 11.9062L14.9062 1.8125L14.9375 1.84375L14.9062 4.65625C14.9062 4.84375 15.0938 5.03125 15.2812 5.03125H15.5938C15.8125 5.03125 15.9688 4.84375 15.9688 4.65625L16 0.375C16 0.1875 15.8125 0 15.625 0Z"
        fill={fill}
      />
    </svg>
  );
};

export default OpenLinkIcon;
