import * as React from 'react';
import styled from '@emotion/styled';
import { Box, BoxProps } from '../Box';

export interface AvatarProps
  extends BoxProps,
    Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> {
  size?: 24 | 48 | 96;
}

const Image = styled('img')<Pick<AvatarProps, 'size'>>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  object-fit: cover;
`;

const Avatar: React.FC<AvatarProps> = ({ size = 48, src, alt, ...rest }) => {
  return (
    <Box
      size={size}
      minWidth={size}
      borderWidth={1}
      borderStyle="solid"
      borderRadius={size}
      borderColor="foreground"
      overflow="hidden"
      {...rest}
    >
      <Image alt={alt} src={src} size={size} />
    </Box>
  );
};

export default Avatar;
