import * as React from 'react';
import Paragraph from './Paragraph';

export interface ListItemProps {
  className?: string;
  style?: React.CSSProperties;
}

const ListItem: React.FC<ListItemProps> = ({ children, ...rest }) => (
  <Paragraph as="li" mb="sm" {...rest}>
    {children}
  </Paragraph>
);

ListItem.displayName = 'ListItem';

export default ListItem;
