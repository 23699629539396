export const colors = {
  black: '#000000',
  white: '#ffffff',
  background: '#22272c',
  accents01: '#282d33',
  accents02: '#2e343b',
  accents03: '#3c4249',
  accents04: '#666b73',
  accents05: '#85888d',
  accents06: '#858a93',
  accents07: '#b8bcc6',
  accents08: '#e1e2e6',
  foreground: '#f1f2f3',
  chart: '#f2c94c',
  brandred: '#d8232a',
  brandgrey: '#b8bcc6',
  error01: '#e55a4f',
  error02: '#d8232a',
  error03: '#b32d2b',
  primary01: '#6b95ef',
  primary02: '#3389fe',
  primary03: '#356dc3',
  success01: '#57a971',
  success02: '#219653',
  success03: '#287e4b',
  warning01: '#f7b955',
  warning02: '#f5a623',
  warning03: '#f49b0b',
  highlight01: '#ff0080',
  highlight02: '#f81ce5',
  highlight03: '#7928ca',
  highlight04: '#79ffe1',

  hoverTable: '#3c4249',
  progressBar: '#3c4249',
  card: '#2e343b', // accents02
  buttonlightmode: '#f1f2f3', // foreground
  buttonlightmodetext: '#22272c', // background
  buttondarkmode: '#d8232a', // brandred
  buttondarkmodetext: '#ffffff', // white
  navgridbgmobile: '#3c4249', // accents03
};

export const systemFonts =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'";
export const systemFontsMonospace =
  "Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace, monospace";

export const fonts = {
  system: systemFonts,
  systemMonospace: systemFontsMonospace,
  sansSerif: `'IBM Plex Sans', ${systemFonts}`,
  serif: "Georgia, 'Times New Roman', Times, serif",
  monospace: `'IBM Plex Mono', ${systemFonts}`,
};

export const space = {
  xxxs: 2,
  xxs: 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 48,
};

export const breakpoints = ['0', '576px', '768px', '992px', '1200px'];

export const mediaQueries = {
  xs: `@media screen and (min-width: ${breakpoints[0]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]})`,
  md: `@media screen and (min-width: ${breakpoints[2]})`,
  lg: `@media screen and (min-width: ${breakpoints[3]})`,
  xl: `@media screen and (min-width: ${breakpoints[4]})`,
  smMax: `@media screen and (max-width: ${breakpoints[1]})`,
};

export const widths = {
  sm: 560,
  md: 750,
  lg: 970,
  xl: 1140,
};

export const shadows = {
  single: '0 4px 8px 0 rgba(0, 0, 0, 0.25)',
  double: '0 8px 16px 0 rgba(0, 0, 0, 0.25)',
};
