import * as React from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Box, Heading, UnstyledAnchor } from 'components/design-system';
import { OpenLinkIcon } from 'components/icons';
import { logEventClick } from 'utils/analytics';

const LinkCard: React.FC<{ name?: string; link?: string }> = ({ name, link }) => {
  const LinkCardAnchor = styled(UnstyledAnchor)`
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  `;

  const LinkCardBase = styled(Box)`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${themeGet('colors.accents02')};
  `;

  return (
    <LinkCardAnchor
      key={name}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => logEventClick(name)}
    >
      <LinkCardBase py="sm">
        <Heading variant={400} fontWeight="normal" as="h5" mr="sm">
          {name}
        </Heading>
        <Box display="flex">
          <OpenLinkIcon />
        </Box>
      </LinkCardBase>
    </LinkCardAnchor>
  );
};

export default LinkCard;
