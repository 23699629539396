import styled from '@emotion/styled';
import * as React from 'react';
import Text from './Text';

export interface OrderedListProps {
  className?: string;
  style?: React.CSSProperties;
}

const OLRoot = styled(Text)`
  display: block;
  list-style-type: decimal;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const OrderedList: React.FC<OrderedListProps> = ({ children, ...rest }) => (
  <OLRoot as="ol" m="sm" ml="md" mb="md" p={0} {...rest}>
    {children}
  </OLRoot>
);

OrderedList.displayName = 'OrderedList';

export default OrderedList;
