import React from 'react';

const YellowWarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 24,
  height = 24,
  fill = '#F2C94C',
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="12" r="9.5" stroke={fill} />
      <path
        d="M11.944 13.648L11.192 9.104V6H13.688V9.104L12.952 13.648H11.944ZM12.424 17.36C11.912 17.36 11.544 17.2427 11.32 17.008C11.1067 16.7733 11 16.4853 11 16.144V15.76C11 15.4187 11.1067 15.1307 11.32 14.896C11.544 14.6613 11.912 14.544 12.424 14.544C12.936 14.544 13.2987 14.6613 13.512 14.896C13.736 15.1307 13.848 15.4187 13.848 15.76V16.144C13.848 16.4853 13.736 16.7733 13.512 17.008C13.2987 17.2427 12.936 17.36 12.424 17.36Z"
        fill={fill}
      />
    </svg>
  );
};

export default YellowWarningIcon;
