import * as React from 'react';

const HomeIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 14,
  fill = '#f1f2f3',
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 14" fill="none" {...rest}>
      <path
        d="M15.9062 6.1875L14 4.625V2.1875C14 2.09375 13.9062 2 13.8125 2H13.1875C13.0625 2 13 2.09375 13 2.1875V3.8125L8.625 0.25C8.46875 0.125 8.1875 0.03125 8 0.03125C7.78125 0.03125 7.5 0.125 7.34375 0.25L0.09375 6.1875C0.03125 6.21875 0 6.3125 0 6.375C0 6.40625 0 6.5 0.03125 6.53125L0.34375 6.90625C0.40625 6.96875 0.5 7 0.5625 7C0.59375 7 0.65625 6.96875 0.71875 6.9375L2 5.90625V13.5C2 13.7812 2.21875 14 2.5 14H6.5C6.75 14 7 13.7812 7 13.5V9.5H9V13.5C9 13.7812 9.21875 14 9.5 14H13.5C13.75 14 14 13.7812 14 13.5V5.90625L15.25 6.9375C15.3125 6.96875 15.375 7 15.4062 7C15.5 7 15.5625 6.96875 15.625 6.90625L15.9375 6.53125C15.9688 6.5 15.9688 6.40625 15.9688 6.375C15.9688 6.3125 15.9375 6.21875 15.9062 6.1875ZM12.9688 13L9.96875 13.0312V9C9.96875 8.75 9.75 8.5 9.5 8.5H6.5H6.46875C6.21875 8.5 5.96875 8.71875 5.96875 9V13H3V5.09375L8 1L13 5.09375L12.9688 13Z"
        fill={fill}
      />
    </svg>
  );
};

export default HomeIcon;
